<template>
  <div>
    <b-modal
      id="complaint-edit-modal"
      centered
      hide-footer
      size="xl"
      @hidden="reset"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      dialog-class="my-dialog-class"
    >
      <h2 class="mb-1">Complaint Details</h2>
      <b-form @submit.prevent>
        <validation-observer ref="complaintEditFormValidation">
          <template>
            <div>
              <b-card no-body class="mb-0 p-1">
                <div class="mb-0 p-1">
                  <b-row>
                    <b-col md="12">
                      <b-row>
                        <!-- <b-col md="3">
                                                <validation-provider #default="{ errors }" name="requestSource"
                                                    rules="required">
                                                    <b-form-group label-for="requestSource"
                                                        :state="errors.length > 0 ? false : null">
                                                        <template #label>
                                                            Request Source <span class="text-danger">*</span>
                                                        </template>
                                                        <b-row>
                                                            <b-col>
                                                                <v-select id="requestSource" inputId="id" label="name"
                                                                    v-model="requestSource" :options="requestSources"
                                                                    placeholder="Request Source" class="v-style-chooser"
                                                                    disabled />
                                                            </b-col>
                                                        </b-row>
                                                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                                            {{ errors[0] }}
                                                        </b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col> -->
                        <!-- <b-col md="3">
                                                <validation-provider #default="{ errors }" name="Type" rules="required">
                                                    <b-form-group label-for="complaintType"
                                                        :state="errors.length > 0 ? false : null">
                                                        <template #label>
                                                            Type <span class="text-danger">*</span>
                                                        </template>
                                                        <b-row>
                                                            <b-col>
                                                                <v-select id="complaintType" inputId="id" label="name"
                                                                    v-model="complaintType" :options="complaintTypes"
                                                                    placeholder="Type" class="v-style-chooser" disabled />
                                                            </b-col>
                                                        </b-row>
                                                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                                            {{ errors[0] }}
                                                        </b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col> -->
                        <!-- <b-col md="3">
                                                <validation-provider #default="{ errors }" name="callerType"
                                                    rules="required">
                                                    <b-form-group label-for="callerType"
                                                        :state="errors.length > 0 ? false : null">
                                                        <template #label>
                                                            Complaint Type <span class="text-danger">*</span>
                                                        </template>
                                                        <b-row>
                                                            <b-col>
                                                                <v-select id="callerType" inputId="id" label="name"
                                                                    v-model="callerType" :options="callerTypes"
                                                                    placeholder="Complaint Type" class="v-style-chooser"
                                                                    :disabled="!allowEdit || !isDraft" />
                                                            </b-col>
                                                        </b-row>
                                                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                                            {{ errors[0] }}
                                                        </b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col> -->
                        <b-col md="3">
                          <b-form-group label-for="cnic">
                            <template #label>
                              CNIC
                              <span class="text-danger">*</span>
                            </template>
                            <validation-provider
                              #default="{ errors }"
                              name="cnic"
                              rules="required|integer|length:13"
                            >
                              <b-form-input
                                id="cnic"
                                v-model="cnic"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Cnic"
                                disabled
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group label-for="cellNo">
                            <template #label> Cell No </template>
                            <validation-provider
                              #default="{ errors }"
                              name="Cell No"
                              rules="integer|length:11"
                            >
                              <b-form-input
                                id="contactNo"
                                v-model="contactNo"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Cell No"
                                disabled
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group label-for="firstName">
                            <template #label>
                              First Name <span class="text-danger">*</span>
                            </template>
                            <validation-provider
                              #default="{ errors }"
                              name="First Name"
                              :rules="{ required, regex: /^[A-Z\s]*$/i }"
                            >
                              <b-form-input
                                id="firstName"
                                v-model="firstName"
                                :state="errors.length > 0 ? false : null"
                                placeholder="First Name"
                                :disabled="!allowEdit || !isDraft"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group label-for="lastName">
                            <template #label>
                              Last Name <span class="text-danger">*</span>
                            </template>
                            <validation-provider
                              #default="{ errors }"
                              name="Last Name"
                              :rules="{ required, regex: /^[A-Z\s]*$/i }"
                            >
                              <b-form-input
                                id="lastName"
                                v-model="lastName"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Last Name"
                                :disabled="!allowEdit || !isDraft"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
                <div class="mb-0 p-1">
                  <b-row>
                    <b-col md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Marital Status"
                        rules="required"
                      >
                        <b-form-group
                          label-for="maritalStatus"
                          :state="errors.length > 0 ? false : null"
                        >
                          <template #label>
                            Marital Status <span class="text-danger">*</span>
                          </template>
                          <b-row>
                            <b-col>
                              <v-select
                                id="maritalStatus"
                                inputId="id"
                                label="name"
                                v-model="maritalStatus"
                                :options="maritalStatuses"
                                placeholder="Marital Status"
                                class="v-style-chooser"
                                disabled
                              />
                            </b-col>
                          </b-row>
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Serving Status"
                        :rules="{ required: isArmy ? isArmy : false }"
                      >
                        <b-form-group
                          label-for="servingStatus"
                          :state="errors.length > 0 ? false : null"
                        >
                          <template #label>
                            Serving Status
                            <span class="text-danger" v-if="isArmy">*</span>
                          </template>
                          <b-row>
                            <b-col>
                              <v-select
                                id="servingStatus"
                                inputId="id"
                                label="name"
                                v-model="servingStatus"
                                :options="servingStatuses"
                                placeholder="Serving Status"
                                class="v-style-chooser"
                                disabled
                              />
                            </b-col>
                          </b-row>
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label-for="contactNo2">
                        <template #label> Cell No 2 </template>
                        <validation-provider
                          #default="{ errors }"
                          name="Cell No"
                          rules="integer|length:11"
                        >
                          <b-form-input
                            id="contactNo2"
                            v-model="contactNo2"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Cell No 2"
                            disabled
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label-for="email">
                        <template #label> Email </template>
                        <validation-provider
                          #default="{ errors }"
                          name="Email"
                          :rules="{ email }"
                        >
                          <b-form-input
                            id="email"
                            v-model="email"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Email"
                            disabled
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-row>
                        <b-col md="3">
                          <validation-provider
                            #default="{ errors }"
                            name="City"
                            rules="required"
                          >
                            <b-form-group
                              label-for="city"
                              :state="errors.length > 0 ? false : null"
                            >
                              <template #label>
                                City <span class="text-danger">*</span>
                              </template>
                              <b-row>
                                <b-col>
                                  <v-select
                                    id="city"
                                    inputId="id"
                                    label="name"
                                    v-model="city"
                                    :options="cities"
                                    placeholder="City"
                                    class="v-style-chooser"
                                    disabled
                                  />
                                </b-col>
                              </b-row>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col md="3">
                          <b-form-group label-for="telephoneNo">
                            <template #label> Telephone No </template>
                            <validation-provider
                              #default="{ errors }"
                              name="Tel No"
                              rules="integer|length:10"
                            >
                              <b-form-input
                                id="telephoneNo"
                                v-model="telephoneNo"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Tel No"
                                disabled
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- <b-col md="3">
                                                    <validation-provider #default="{ errors }" name="ComplainantType"
                                                        rules="required">
                                                        <b-form-group label-for="complainantType"
                                                            :state="errors.length > 0 ? false : null">
                                                            <template #label>
                                                                Complainant Type <span class="text-danger">*</span>
                                                            </template>
                                                            <b-row>
                                                                <b-col>
                                                                    <v-select id="complainantType" inputId="id" label="name"
                                                                        v-model="complainantType"
                                                                        :options="currentComplainantTypes"
                                                                        placeholder="ComplainantType"
                                                                        class="v-style-chooser" :disabled="!allowEdit" />
                                                                </b-col>
                                                            </b-row>
                                                            <b-form-invalid-feedback
                                                                :state="errors.length > 0 ? false : null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col> -->
                      </b-row>
                      <b-row v-if="!callerSelf">
                        <b-col md="12">
                          <b-row>
                            <b-col md="3">
                              <validation-provider
                                #default="{ errors }"
                                name="relationType"
                                :rules="{
                                  required: callerSelf ? callerSelf : false,
                                }"
                              >
                                <b-form-group
                                  label-for="relationType"
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <template #label>
                                    Relation Type
                                    <span class="text-danger">*</span>
                                  </template>
                                  <b-row>
                                    <b-col>
                                      <v-select
                                        id="relationType"
                                        inputId="id"
                                        label="name"
                                        v-model="relationType"
                                        :options="relationTypes"
                                        placeholder="Relation Type"
                                        class="v-style-chooser"
                                        disabled
                                      />
                                    </b-col>
                                  </b-row>
                                  <b-form-invalid-feedback
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    {{ errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col md="3">
                              <b-form-group label-for="relationName">
                                <template #label>
                                  Relation Name
                                  <span class="text-danger">*</span>
                                </template>
                                <validation-provider
                                  #default="{ errors }"
                                  name="Relation Name"
                                  :rules="{
                                    required: callerSelf ? callerSelf : false,
                                    regex: /^[A-Z\s]*$/i,
                                  }"
                                >
                                  <b-form-input
                                    id="relationName"
                                    v-model="relationName"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Relation Name"
                                    disabled
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group label-for="relationCnic">
                                <template #label>
                                  Relation CNIC
                                  <span class="text-danger">*</span>
                                </template>
                                <validation-provider
                                  #default="{ errors }"
                                  name="Relation Cnic"
                                  :rules="{
                                    required: callerSelf ? callerSelf : false,
                                    integer: true,
                                    length: 13,
                                  }"
                                >
                                  <b-form-input
                                    id="relationCnic"
                                    v-model="relationCnic"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Relation Cnic"
                                    disabled
                                    
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group label-for="relationContactNo">
                                <template #label> Relation Cell No </template>
                                <validation-provider
                                  #default="{ errors }"
                                  name="Cell No"
                                  rules="integer|length:11"
                                >
                                  <b-form-input
                                    id="relationContactNo"
                                    v-model="relationContactNo"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Relation Cell No"
                                    disabled
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row> </b-row>
                  <!-- <b-row>
                                        <b-col md="3">
                                            <validation-provider #default="{ errors }" name="Category" rules="required">
                                                <b-form-group label-for="category"
                                                    :state="errors.length > 0 ? false : null">
                                                    <template #label>
                                                        Category <span class="text-danger">*</span>
                                                    </template>
                                                    <b-row>
                                                        <b-col>
                                                            <v-select id="category" inputId="id" label="name"
                                                                v-model="category" :options="categories"
                                                                placeholder="Category" class="v-style-chooser"
                                                                :disabled="!allowEdit" />
                                                        </b-col>
                                                    </b-row>
                                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                                        {{ errors[0] }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="3" v-if="category">
                                            <validation-provider #default="{ errors }" name="Subcategory" rules="required">
                                                <b-form-group label-for="subCategory"
                                                    :state="errors.length > 0 ? false : null">
                                                    <template #label>
                                                        Subcategory <span class="text-danger">*</span>
                                                    </template>
                                                    <b-row>
                                                        <b-col>
                                                            <v-select id="subCategory" inputId="id" label="name"
                                                                v-model="subCategory" :options="selectedSubcategories"
                                                                placeholder="Subcategory" class="v-style-chooser"
                                                                :disabled="subcategoryDisabled || !allowEdit" />
                                                        </b-col>
                                                    </b-row>
                                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                                        {{ errors[0] }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                    </b-row> -->
                </div>
                <div class="mb-0 p-1" v-if="markingAllowed">
                  <b-row>
                    <!-- <b-col md="6">
                                            <b-form-group>
                                                <template #label>
                                                    Mark To: <span class="text-danger">*</span>
                                                </template>
                                                <v-select id="selectedOrganization" inputId="id" label="name"
                                                    v-model="selectedOrganization" :options="organizationsList"
                                                    placeholder="Select organization" class="v-style-chooser"
                                                    :disabled="!allowEdit" />
                                            </b-form-group>
                                        </b-col> -->
                    <b-col md="6">
                      <b-form-group label-for="documents">
                        <validation-provider
                          #default="{ errors }"
                          name="Document"
                        >
                          <label>Document (PDF only)</label>
                          <b-form-file
                            ref="fileInputEdit"
                            id="documentsEdit"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            accept=".pdf"
                            @change="upload"
                            v-model="uploadedFiles"
                            disabled
                          />
                          <small class="text-danger" v-if="documentError === 1"
                            >Max {{ maxFilesCount }} files are allowed</small
                          >
                          <small class="text-danger" v-if="documentError === 2"
                            >Each file size should be less than
                            {{ maxFileSize }} MB</small
                          >
                          <small class="text-danger" v-if="documentError === 3"
                            >Only pdf is allowed</small
                          >
                          <div class="d-flex flex-wrap">
                            <b-badge
                              pill
                              variant="primary"
                              v-for="(file, index) in uploadedFiles"
                              :key="index"
                              class="my-1 mx-25"
                            >
                              <b-row>
                                <b-col md="9">
                                  <div
                                    class="cursor-pointer"
                                    @click="viewDoc(complaint.documents[index])"
                                  >
                                    {{ file.name }}
                                  </div>
                                </b-col>
                                <b-col md="3" v-if="allowEdit">
                                  <feather-icon
                                    size="24"
                                    icon="XIcon"
                                    class="cursor-pointer mr-50"
                                    @click="removeFile(index)"
                                  />
                                </b-col>
                              </b-row>
                            </b-badge>
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <div class="mb-0 p-1">
                  <h4 class="font-weight-bolder">Complaint Detail</h4>
                  <b-row>
                    <b-col>
                      <b-card>
                        <b-row>
                          <b-col md="12">
                            <b-form-group label-for="comment">
                            
                                <quill-editor
                                  v-model="comment"
                                  :options="option"
                                  style="height: 200px"
                                  disabled
                                />
                                <!-- <b-form-textarea id="comment" v-model="comment"
                                                                :state="errors.length > 0 ? false : null"
                                                                placeholder="comment" :disabled="!allowEdit" />
                                                            <small class="text-danger">{{ errors[0] }}</small> -->
                              
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </div>
          </template>
        </validation-observer>
        <!-- <template v-if="markingAllowed">
                    <validation-observer ref="complaintMarkingFormValidation">
                        <template>
                            <div>
                                <b-card>
                                    <b-row>
                                        <b-col md="3" class="mt-1">
                                            Mark To:
                                        </b-col>
                                        <b-col md="9">
                                            <v-select id="selectedOrganization" inputId="id" label="name"
                                                v-model="selectedOrganization" :options="organizationsList"
                                                placeholder="Select organization" class="v-style-chooser"
                                                :disabled="!allowEdit" />
                                        </b-col>
                                    </b-row>
                                     <b-row align-v="center" class="mt-1">
                                <b-col md="9">
                                <VueSelectPaginated name="Documents" label="name" :multiple="true" :prevSelected="markDocuments"
                                    :parameters="{ created_by: getLoggedInUser.id }" :getListMethod="getDocuments"
                                    @setMethod="(value) => (markDocuments = value)" :key="documentsKey" />
                                <div class="d-flex flex-wrap">
                                    <b-badge pill variant="primary" v-for="(file, index) in markDocuments" :key="`file-${index}`"
                                    class="mr-50 mb-50 cursor-pointer" @click="openFile(file.path)">
                                    {{ file.name }}
                                    </b-badge>
                                </div>
                                </b-col>
                                <b-col md="3">
                                <b-button variant="primary" @click="createDocument(false)" class="mt-50"
                                    v-if="hasPermission('create_document')">
                                    <feather-icon icon="PlusIcon" class="mr-50" />
                                    <span class="align-middle">Upload</span>
                                </b-button>
                                </b-col>
                            </b-row> 
                                    <b-form-group label-for="documents">
                                        <validation-provider #default="{ errors }" name="Document">
                                            <label>Document (PDF only)</label>
                                            <b-form-file ref="fileInputEdit" id="documentsEdit"
                                                placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..." accept=".pdf" @change="upload"
                                                :disabled="!allowEdit" />
                                            <small class="text-danger" v-if="documentError === 1">Max {{ maxFilesCount }}
                                                files are allowed</small>
                                            <small class="text-danger" v-if="documentError === 2">Each file size should be
                                                less than {{ maxFileSize }} MB</small>
                                            <small class="text-danger" v-if="documentError === 3">Only pdf is
                                                allowed</small>
                                            <div class="d-flex flex-wrap">
                                                <b-badge pill variant="primary" v-for="(file, index) in uploadedFiles"
                                                    :key="index" class="my-1 mx-25">
                                                    <b-row>
                                                        <b-col md="9">
                                                            <div class="cursor-pointer"
                                                                @click="viewDoc(complaint.documents[index])">
                                                                {{ file.name }}
                                                            </div>
                                                        </b-col>
                                                        <b-col md="3" v-if="allowEdit">
                                                            <feather-icon size="24" icon="XIcon"
                                                                class="cursor-pointer mr-50" @click="removeFile(index)" />
                                                        </b-col>
                                                    </b-row>
                                                </b-badge>
                                            </div>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-card>
                            </div>
                        </template>
                    </validation-observer>
                </template> -->
      </b-form>
      <b-form-group
        class="mt-1 text-right"
        v-if="
          complaint &&
          complaint.status !== complaintStatus.FINALIZED &&
          complaint.status !== complaintStatus.CLOSED
        "
      >
        <!-- <b-button
          type="submit"
          variant="info"
          pill
          class="mr-1"
          v-if="allowEdit && isDraft"
          @click="saveDraft"
        >
          Update
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          pill
          class="mr-1"
          @click="validationForm"
          v-if="allowEdit"
        >
          <template v-if="!isDraft"> Update </template>
          <template v-if="isDraft"> Update & Submit </template>
        </b-button>
        <b-button
          type="submit"
          variant="info"
          pill
          class="mr-1"
          @click="validationForm"
          v-if="onlyMark"
        >
          Mark
        </b-button> -->
      </b-form-group>
    </b-modal>
    <document-create-modal
      id="document-edit-modal"
      :isComplaintDocument="isComplaintDocument"
      @modalClosed="onDocumentCreateModalClosed"
      :key="`create-document-${documentCreateModalCount}`"
    />
  </div>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import DocumentCreateModal from "@/components/document/DocumentCreateModal.vue";
import ComplaintHistorySummaryForm from "@/components/complaint/ComplaintHistorySummaryForm.vue";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VuePerfectScrollbar,
    draggable,
    VueSelectPaginated,
    DocumentCreateModal,
    ComplaintHistorySummaryForm,
    quillEditor,
  },
  props: {
    complaint: Object,
    allowEdit: {
      type: Boolean,
      default: false,
    },
    markingAllowed: {
      type: Boolean,
      default: false,
    },
    onlyMark: {
      type: Boolean,
      default: false,
    },
    // isDraft: {
    //     type: Boolean,
    //     default: false,
    // },
  },
  mixins: [util],
  data() {
    return {
      isDraft: true,
      homeFormState: false,
      markingFormState: false,
      required,
      type: "",
      rollNo: "",
      name: "",
      dateOfBirth: "",
      cnic: "",
      gender: "",
      homeDistrict: "",
      telNo: "",
      cellNo: "",
      imageURL: "",
      cnicOrBform: null,
      uploadImage: null,

      // Complaint Fields
      comment: "",
      markingComment: "",
      requestSource: null,
      callerType: null,
      relationType: null,
      complainantType: null,
      complaintType: null,
      category: null,
      subCategory: null,
      requestSources: [],
      callerTypes: [],
      relationTypes: [],
      complainantTypes: [],
      complaintTypes: [],
      subCategories: [],
      categories: [],
      commentOptions: {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            ["bold", "italic", "underline", "strike"], // toggled buttons

            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
          ],
        },
      },
      option: {
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
            ["clean"], // remove formatting button
          ],
        },
      },
      subcategoryDisabled: false,
      allCategories: [],
      selectedSubcategories: [],
      callerSelf: false,

      // Complainant Fields
      email: "",
      armyNo: "",
      cnic: "",
      unit: "",
      firstName: "",
      lastName: "",
      contactNo: "",
      contactNo2: "",
      telephoneNo: "",
      rank: null,
      city: null,
      armyPrefix: null,
      maritalStatus: null,
      regiment: null,
      servingStatus: null,
      armyPrefixes: [],
      maritalStatuses: [],
      regiments: [],
      servingStatuses: [],
      ranks: [],
      cities: [],
      isArmy: true,

      //Relation Fields
      relationName: "",
      relationCnic: "",
      relationContactNo: "",

      //FollowUp Fields
      followUpName: "",
      followUpContactNo: "",
      followUpTelephoneNo: "",

      //SearchField
      searchField: "",

      // Marking
      selectedOrganization: null,
      organizationsList: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 100,
        wheelPropagation: false,
        useBothWheelAxes: false,
        suppressScrollX: true,
        FavoriteUserKey: 0,
        FavoriteUser: null,
      },
      markingOptions: {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            ["bold", "italic", "underline", "strike"], // toggled buttons

            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
          ],
        },
      },

      // Documents
      documents: [],
      markDocuments: [],
      documentsKey: 0,
      documentCreateModalCount: 0,
      isComplaintDocument: false,
      complainantsList: [],
      saveAsDraft: false,

      documentError: 0,
      maxFilesCount: 5,
      maxFileSize: 5,
      uploadedFiles: [],
      acceptedFileTypes: ".pdf",

      currentComplainantTypes: [],
    };
  },

  async mounted() {
    if (this.complaint) {
      const res = await this.getOrganizationsUnpaginated();
      this.organizationsList = res.data;
      await this.getComplaintFormFieldsData();

      this.comment = this.complaint.comment;
      this.isDraft = this.complaint.draft;

      for (let i = 0; i < this.requestSources.length; i++) {
        if (this.requestSources[i].id == this.complaint.request_source) {
          this.requestSource = this.requestSources[i];
        }
      }

      for (let i = 0; i < this.callerTypes.length; i++) {
        if (this.callerTypes[i].id == this.complaint.caller_type) {
          this.callerType = this.callerTypes[i];
        }
      }

      for (let i = 0; i < this.complainantTypes.length; i++) {
        if (this.complainantTypes[i].id == this.complaint.complainant_type) {
          this.complainantType = this.complainantTypes[i];
        }
      }

      for (let i = 0; i < this.relationTypes.length; i++) {
        if (this.relationTypes[i].id == this.complaint.relation_type) {
          this.relationType = this.relationTypes[i];
        }
      }
      // for (let i = 0; i < this.subCategories.length; i++) {
      //     if (this.subCategories[i].id == this.complaint.category) {
      //         this.subCategory = this.subCategories[i]
      //     }
      // }
      // for (let i = 0; i < this.categories.length; i++) {
      //     if (this.categories[i].id == this.subCategory.parent) {
      //         this.category = this.categories[i]
      //     }
      // }

      // Complainant data
      // for (let i = 0; i < this.complaintTypes.length; i++) {
      //     if (this.complaintTypes[i].id == this.complaint.complainant.complaint_type) {
      //         this.complaintType = this.complaintTypes[i]
      //     }
      // }
      for (let i = 0; i < this.armyPrefixes.length; i++) {
        if (this.armyPrefixes[i].id == this.complaint.complainant.army_prefix) {
          this.armyPrefix = this.armyPrefixes[i];
        }
      }
      for (let i = 0; i < this.ranks.length; i++) {
        if (this.ranks[i].id == this.complaint.complainant.rank) {
          this.rank = this.ranks[i];
        }
      }
      for (let i = 0; i < this.maritalStatuses.length; i++) {
        if (
          this.maritalStatuses[i].id ==
          this.complaint.complainant.marital_status
        ) {
          this.maritalStatus = this.maritalStatuses[i];
        }
      }
      for (let i = 0; i < this.regiments.length; i++) {
        if (this.regiments[i].id == this.complaint.complainant.regiment) {
          this.regiment = this.regiments[i];
        }
      }
      for (let i = 0; i < this.servingStatuses.length; i++) {
        if (
          this.servingStatuses[i].id ==
          this.complaint.complainant.serving_status
        ) {
          this.servingStatus = this.servingStatuses[i];
        }
      }
      for (let i = 0; i < this.cities.length; i++) {
        if (this.cities[i].id == this.complaint.complainant.city) {
          this.city = this.cities[i];
        }
      }
      this.armyNo = this.complaint.complainant.army_no;
      this.contactNo = this.complaint.complainant.contact_no;
      this.contactNo2 = this.complaint.complainant.contact_no2;
      this.cnic = this.complaint.complainant.cnic;
      this.unit = this.complaint.complainant.unit;
      this.firstName = this.complaint.complainant.first_name;
      this.lastName = this.complaint.complainant.last_name;
      this.email = this.complaint.complainant.email;
      this.telephoneNo = this.complaint.complainant.telephone_no;

      // Relation Data
      if (this.complaint.relation) {
        this.relationContactNo = this.complaint.relation.contact_no;
        this.relationName = this.complaint.relation.name;
        this.relationCnic = this.complaint.relation.cnic;
      }
      // FollowUp Data
      // this.followUpContactNo = this.complaint.followup.contact_no
      // this.followUpName = this.complaint.followup.name
      // this.followUpTelephoneNo = this.complaint.followup.telephone_no
      // this.parentComplaints = this.complaint.parent_complaints_data;
      // this.childComplaints = this.complaint.child_complaints_data;

      for (let i = 0; i < this.organizationsList.length; i++) {
        if (this.organizationsList[i].id == this.complaint.marked_to) {
          this.selectedOrganization = this.organizationsList[i];
          break;
        }
      }

      // if (this.complaint.draft) {
      if (this.complaint.documents) {
        for (let i = 0; i < this.complaint.documents.length; i++) {
          fetch(this.complaint.documents[i].path)
            .then((response) => response.blob())
            .then((blob) => {
              this.$refs.fileInputEdit.files.push(
                new File([blob], this.complaint.documents[i].file_data.name)
              );
              this.uploadedFiles.push(this.$refs.fileInputEdit.files[i]);
            });
          
        }
        // }
      }
    }
  },
  methods: {
    ...mapActions({
      updateComplaint: "appData/updateComplaint",
      getComplaintFormFields: "appData/getComplaintFormFields",
      getOrganization: "appData/getOrganization",
      getOrganizations: "appData/getOrganizations",
      getUser: "appData/getUser",
      getDocuments: "appData/getDocuments",
      getUsersUnPaginated: "appData/getUsersUnPaginated",
      getOrganizationsUnpaginated: "appData/getOrganizationsUnpaginated",
    }),
    viewDoc(file) {
      window.open(file.path, "_blank");
    },
    async upload(event) {
      this.documentError = 0;
      const files = event.target.files;
      if (this.uploadedFiles.length + files.length > this.maxFilesCount) {
        this.documentError = 1;
        return;
      }
      if (!files[0].name.includes(".pdf")) {
        this.documentError = 3;
        return;
      }

      const filesToAdd = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size > this.maxFileSize * 100 * 1024) {
          this.documentError = 2;
          return;
        }

        filesToAdd.push(file);
      }

      this.uploadedFiles = [...this.uploadedFiles, ...filesToAdd];
    },
    removeFile(index) {
      this.documentError = 0;
      this.uploadedFiles.splice(index, 1);
    },
    async saveDraft() {
      this.saveAsDraft = true;
      await this.validationForm();
    },
    async validationForm() {
      let success = await this.$refs.complaintEditFormValidation.validate();
      if (success) {
        this.homeFormState = false;
        if (this.markingAllowed == true) {
          // success =
          //   await this.$refs.complaintMarkingFormValidation.validate();
          // if (success) {
          //   this.markingFormState = false;
          //   await this.submit();
          // } else {
          //   this.markingFormState = true;
          // }
          this.markingFormState = false;
          await this.submit();
        } else {
          await this.submit();
        }
      } else {
        this.homeFormState = true;
      }
    },
    createDocument(isComplaintDocument) {
      this.isComplaintDocument = isComplaintDocument;
      this.documentCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("document-edit-modal");
      });
    },
    async onDocumentCreateModalClosed(documents, isComplaintDocument) {
      if (isComplaintDocument) {
        this.documents = this.documents.concat(documents);
      } else {
        this.markDocuments = this.markDocuments.concat(documents);
      }
      this.documentsKey += 1;
    },
    async getComplaintFormFieldsData() {
      try {
        const res = await this.getComplaintFormFields();
        this.requestSources = res.data.request_sources;
        this.callerTypes = res.data.caller_types;
        this.relationTypes = res.data.relation_types;
        this.complainantTypes = res.data.complainant_types;
        this.complaintTypes = res.data.complaint_types;

        this.armyPrefixes = res.data.army_prefixes;
        this.maritalStatuses = res.data.marital_statuses;
        this.regiments = res.data.regiments;
        this.servingStatuses = res.data.serving_statuses;
        this.ranks = res.data.rank_types;
        this.cities = res.data.cities;

        this.categories = res.data.categories;
        this.subCategories = res.data.subcategories;
      } catch (error) {
        this.displayError(error);
      }
    },
    async submit() {
      try {
        let users;
        // let mark_documents_id;
        if (this.markingAllowed == true) {
          if (!this.selectedOrganization) {
            this.displayError("Complaint has to be marked");
            return;
          }
          // mark_documents_id = this.markDocuments.map((document) => {
          //     return document.id;
          // });
        }

        let formData = new FormData();

        this.uploadedFiles.map((file, index) => {
          formData.append(`file${index}`, file);
        });

        let dataToInsert = {
          complainant: {
            complaint_type: this.complaintType.id,
            army_prefix: this.armyPrefix ? this.armyPrefix.id : null,
            army_no: this.armyNo,
            cnic: this.cnic,
            rank: this.rank ? this.rank.id : null,
            unit: this.unit,
            marital_status: this.maritalStatus.id,
            first_name: this.firstName,
            last_name: this.lastName,
            regiment: this.regiment ? this.regiment.id : null,
            serving_status: this.servingStatus ? this.servingStatus.id : null,
            contact_no: this.contactNo,
            contact_no2: this.contactNo2,
            email: this.email,
            city: this.city.id,
            telephone_no: this.telephoneNo,
            updated_by: this.getLoggedInUser.id,
          },
          // followup: {
          //     name: this.followUpName,
          //     contact_no: this.followUpContactNo,
          //     telephone_no: this.followUpTelephoneNo,
          //     updated_by: this.getLoggedInUser.id,
          // },

          request_source: this.requestSource.id,
          caller_type: this.callerType.id,
          complainant_type: null,
          relation_type: this.relationType ? this.relationType.id : null,
          comment: this.comment,
          category: null,
          draft: this.saveAsDraft,
          created_by: this.getLoggedInUser.id,
          updated_by: this.getLoggedInUser.id,
        };
        if (this.markingAllowed == true) {
          dataToInsert["marked_from"] = this.getLoggedInUser.id;
          dataToInsert["marked_to"] = this.selectedOrganization.id;
        }
        if (this.callerSelf == false) {
          dataToInsert["relation"] = {
            name: this.relationName,
            cnic: this.relationCnic,
            contact_no: this.relationContactNo,
            updated_by: this.getLoggedInUser.id,
          };
        }
        formData.append("data", JSON.stringify(dataToInsert));
        let msg = "";
        if (this.onlyMark) {
          msg = "Complaint Marked successfully";
        } else {
          msg = "Complaint Edited successfully";
        }
        const res = await this.updateComplaint({
          payload: formData,
          pk: this.complaint.id,
        });
        if (res.status === 200) {
          this.$swal({
            title: msg,
            icon: "success",
          });
          // this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("complaint-edit-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.$bvModal.hide("complaint-edit-modal");
      this.$emit("modalClosed");
    },
  },
  computed: {
    ...mapGetters({
      getLoggedInUser: "appData/getUser",
      hasPermission: "appData/hasPermission",
    }),
    imgURL() {
      return this.imageURL;
    },
  },
  watch: {
    category(newValue, oldValue) {
      if (newValue) {
        if (oldValue) {
          this.subCategory = null;
        }
        this.selectedSubcategories = [];
        this.subcategoryDisabled = false;
        for (let i = 0; i < this.subCategories.length; i++) {
          if (this.subCategories[i].parent == newValue.id) {
            this.selectedSubcategories.push(this.subCategories[i]);
          }
        }
        if (
          this.selectedSubcategories === undefined ||
          this.selectedSubcategories.length == 0
        ) {
          this.subcategoryDisabled = true;
        }
      }
    },
    complaintType(newValue, oldValue) {
      if (newValue) {
        if (newValue.name.toLowerCase().includes("army")) {
          this.isArmy = true;
        } else if (newValue.name.toLowerCase().includes("civilian")) {
          this.isArmy = false;
        }
      }
    },
    callerType(newValue, oldValue) {
      if (newValue) {
        if (newValue["name"].toLowerCase().includes("self")) {
          this.callerSelf = true;
          if (oldValue && this.isDraft) {
            this.contactNo = this.getLoggedInUser.mobile;
            this.cnic = this.getLoggedInUser.cnic;
            for (let i = 0; i < this.relationTypes.length; i++) {
              if (this.relationTypes[i].id == this.complaint.relation_type) {
                this.relationType = this.relationTypes[i];
              }
            }
            this.relationContactNo = this.complaint.relation.contact_no;
            this.relationName = this.complaint.relation.name;
            this.relationCnic = this.complaint.relation.cnic;
            this.complainantType = null;
          }
          this.currentComplainantTypes = [];
          for (let i = 0; i < this.complainantTypes.length; i++) {
            if (
              !this.complainantTypes[i].name
                .toLowerCase()
                .includes("shaheed") &&
              !this.complainantTypes[i].name.toLowerCase().includes("death") &&
              !this.complainantTypes[i].name.toLowerCase().includes("widow") &&
              !this.complainantTypes[i].name.toLowerCase().includes("orphan")
            ) {
              this.currentComplainantTypes.push(this.complainantTypes[i]);
            }
          }
        } else {
          this.callerSelf = false;
          if (oldValue && this.isDraft) {
            this.relationContactNo = this.getLoggedInUser.mobile;
            this.relationCnic = this.getLoggedInUser.cnic;
            this.contactNo = this.complaint.complainant.contact_no;
            this.cnic = this.complaint.complainant.cnic;
            this.complainantType = null;
          }
          this.currentComplainantTypes = this.complainantTypes;
        }
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
@import "@core/scss/vue/libs/quill.scss";

.scroll-area-size {
  height: 30vh;
}

// label {
//   font-size: 110%;
//   font-weight: 550 !important;
//   color: #000;
// }

.scroll-area-size ul {
  height: 100%;
}

.quill-editor {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.quill-editor {
  overflow-y: hidden;
}

.vSelectStyle .vs__deselect {
  fill: #fff !important;
}
</style>
  