<template>
  <div>
    <h2 class="mb-1">Create Complaint</h2>
    <b-form @submit.prevent>
      <validation-observer ref="complaintCreateFormValidation">
        <template>
          <div>
            <b-card no-body class="mb-0">
              <b-row>
                <b-col md="12">
                  <b-row>
                    <!-- <b-col md="3">
                      <validation-provider #default="{ errors }" name="requestSource" rules="required">
                        <b-form-group label-for="requestSource" :state="errors.length > 0 ? false : null">
                          <template #label>
                            Request Source <span class="text-danger">*</span>
                          </template>
                          <b-row>
                            <b-col>
                              <v-select id="requestSource" inputId="id" label="name" v-model="requestSource"
                                :options="requestSources" placeholder="Request Source" class="v-style-chooser" disabled />
                            </b-col>
                          </b-row>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col> -->
                    <!-- <b-col md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Type"
                        rules="required"
                      >
                        <b-form-group
                          label-for="complaintType"
                          :state="errors.length > 0 ? false : null"
                        >
                          <template
                            ><label> Type </label
                            ><span class="text-danger">*</span>
                          </template>
                          <b-row>
                            <b-col>
                              <v-select
                                id="complaintType"
                                inputId="id"
                                label="name"
                                v-model="complaintType"
                                :options="complaintTypes"
                                placeholder="Type"
                                class="v-style-chooser"
                                disabled
                              />
                            </b-col>
                          </b-row>
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col> -->
                    <b-col md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="callerType"
                        rules="required"
                      >
                        <b-form-group
                          label-for="callerType"
                          :state="errors.length > 0 ? false : null"
                        >
                          <template
                            ><label> Complaint Type </label
                            ><span class="text-danger">*</span>
                          </template>
                          <b-row>
                            <b-col>
                              <v-select
                                id="callerType"
                                inputId="id"
                                label="name"
                                v-model="callerType"
                                :options="callerTypes"
                                placeholder="Complaint Type"
                                class="v-style-chooser"
                                v-b-tooltip.hover
                                title="Select an option from dropdown e.g. Self."
                              />
                            </b-col>
                          </b-row>
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label-for="cnic">
                        <template
                          ><label> CNIC</label>
                          <span class="text-danger">*</span>
                        </template>
                        <validation-provider
                          #default="{ errors }"
                          name="cnic"
                          rules="required|integer|length:13"
                        >
                          <b-form-input
                            id="cnic"
                            v-model="cnic"
                            :state="
                              errors.length > 0 ||
                              (!callerSelf
                                ? cnic == relationCnic
                                  ? true
                                  : null
                                : null)
                                ? false
                                : null
                            "
                            placeholder="Cnic"
                            :disabled="callerSelf"
                            v-b-tooltip.hover
                            title="Enter complainant CNIC e.g 1234567891230"
                          />
                          <small class="text-danger">{{
                            errors.length > 0
                              ? errors[0]
                              : !callerSelf
                              ? cnic == relationCnic
                                ? "CNIC cannot be the same as relation CNIC"
                                : null
                              : null
                          }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label-for="cellNo">
                        <template><label> Cell No</label> </template>
                        <validation-provider
                          #default="{ errors }"
                          name="Cell No"
                          rules="integer|length:11"
                        >
                          <b-form-input
                            id="contactNo"
                            v-model="contactNo"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Cell No"
                            :disabled="callerSelf"
                            v-b-tooltip.hover
                            title="Enter mobile number e.g 03123456789"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label-for="firstName">
                        <template
                          ><label> First Name </label>
                          <span class="text-danger">*</span>
                        </template>
                        <validation-provider
                          #default="{ errors }"
                          name="First Name"
                          :rules="{ required, regex: /^[A-Z\s]*$/i }"
                        >
                          <b-form-input
                            id="firstName"
                            v-model="firstName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="First Name"
                            v-b-tooltip.hover
                            title="Enter complainant first name e.g Muhammad"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
            <b-card no-body class="mb-0">
              <b-row>
                <b-col md="3">
                  <b-form-group label-for="lastName">
                    <template
                      ><label> Last Name </label>
                      <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Last Name"
                      :rules="{ required, regex: /^[A-Z\s]*$/i }"
                    >
                      <b-form-input
                        id="lastName"
                        v-model="lastName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Last Name"
                        v-b-tooltip.hover
                        title="Enter complainant last name e.g Ali"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <validation-provider
                    #default="{ errors }"
                    name="Marital Status"
                    rules="required"
                  >
                    <b-form-group
                      label-for="maritalStatus"
                      :state="errors.length > 0 ? false : null"
                    >
                      <template>
                        <label> Marital Status</label>
                        <span class="text-danger">*</span>
                      </template>
                      <b-row>
                        <b-col>
                          <v-select
                            id="maritalStatus"
                            inputId="id"
                            label="name"
                            v-model="maritalStatus"
                            :options="maritalStatuses"
                            placeholder="Marital Status"
                            class="v-style-chooser"
                            v-b-tooltip.hover
                            title="Select an option from the dropdown menu e.g Married"
                          />
                        </b-col>
                      </b-row>
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- <b-col md="3">
                  <validation-provider
                    #default="{ errors }"
                    name="Serving Status"
                    :rules="{ required: isArmy ? isArmy : false }"
                  >
                    <b-form-group
                      label-for="servingStatus"
                      :state="errors.length > 0 ? false : null"
                    >
                      <template>
                        <label> Serving Status</label>
                        <span class="text-danger" v-if="isArmy">*</span>
                      </template>
                      <b-row>
                        <b-col>
                          <v-select
                            id="servingStatus"
                            inputId="id"
                            label="name"
                            v-model="servingStatus"
                            :options="servingStatuses"
                            placeholder="Serving Status"
                            class="v-style-chooser"
                            :disabled="!isArmy"
                          />
                        </b-col>
                      </b-row>
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col> -->
                <b-col md="3">
                  <b-form-group label-for="contactNo2">
                    <template> <label> Cell No 2</label> </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Cell No"
                      rules="integer|length:11"
                    >
                      <b-form-input
                        id="contactNo2"
                        v-model="contactNo2"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Cell No 2"
                        v-b-tooltip.hover
                        title="Enter your other mobile number e.g 03123456789"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label-for="email">
                    <template><label> Email</label> </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      :rules="{ email }"
                    >
                      <b-form-input
                        id="email"
                        v-model="email"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Email"
                        v-b-tooltip.hover
                        title="Enter email address e.g abc@xyz.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-row>
                    <b-col md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="City"
                        rules="required"
                      >
                        <b-form-group
                          label-for="city"
                          :state="errors.length > 0 ? false : null"
                        >
                          <template>
                            <label> City </label>
                            <span class="text-danger">*</span>
                          </template>
                          <b-row>
                            <b-col>
                              <v-select
                                id="city"
                                inputId="id"
                                label="name"
                                v-model="city"
                                :options="cities"
                                placeholder="City"
                                class="v-style-chooser"
                                v-b-tooltip.hover
                                title="Select your city from dropdown menu e.g Islamabad"
                              />
                            </b-col>
                          </b-row>
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label-for="telephoneNo">
                        <template><label> Telephone No </label> </template>
                        <validation-provider
                          #default="{ errors }"
                          name="Tel No"
                          rules="integer|length:10"
                        >
                          <b-form-input
                            id="telephoneNo"
                            v-model="telephoneNo"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Tel No"
                            v-b-tooltip.hover
                            title="Enter telephone number e.g 051456789"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col md="3">
                      <validation-provider #default="{ errors }" name="ComplainantType" rules="required">
                        <b-form-group label-for="complainantType" :state="errors.length > 0 ? false : null">
                          <template><label> Complainant Type</label>
                            <span class="text-danger">*</span>
                          </template>
                          <b-row>
                            <b-col>
                              <v-select id="complainantType" inputId="id" label="name" v-model="complainantType"
                                :options="currentComplainantTypes" placeholder="ComplainantType"
                                class="v-style-chooser"
                                 v-b-tooltip.hover title="Select complaint type from dropdown menu e.g War Wounded" />
                            </b-col>
                          </b-row>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col> -->
                    <!-- <b-col md="3">
                      <validation-provider #default="{ errors }" name="Category" rules="required">
                        <b-form-group label-for="category" :state="errors.length > 0 ? false : null">
                          <template>
                            <label> Category </label>
                            <span class="text-danger">*</span>
                          </template>
                          <b-row>
                            <b-col>
                              <v-select id="category" inputId="id" label="name" v-model="category" :options="categories"
                                placeholder="Category" class="v-style-chooser"  v-b-tooltip.hover title="Select categoty from dropdown menu e.g Medical" />
                            </b-col>
                          </b-row>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col> -->
                  </b-row>
                  <b-row v-if="!callerSelf">
                    <b-col md="12">
                      <b-row>
                        <b-col md="3">
                          <validation-provider
                            #default="{ errors }"
                            name="relationType"
                            :rules="{
                              required: !callerSelf,
                            }"
                          >
                            <b-form-group
                              label-for="relationType"
                              :state="errors.length > 0 ? false : null"
                            >
                              <label>
                                Relation Type <span class="text-danger">*</span>
                              </label>
                              <b-row>
                                <b-col>
                                  <v-select
                                    id="relationType"
                                    inputId="id"
                                    label="name"
                                    v-model="relationType"
                                    :options="relationTypes"
                                    placeholder="Relation Type"
                                    class="v-style-chooser"
                                    v-b-tooltip.hover
                                    title="Select relation from dropdown menu e.g Wife"
                                  />
                                </b-col>
                              </b-row>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col md="3">
                          <b-form-group label-for="relationName">
                            <label>
                              Relation Name <span class="text-danger">*</span>
                            </label>
                            <validation-provider
                              #default="{ errors }"
                              name="Relation Name"
                              :rules="{
                                required: !callerSelf,
                                regex: /^[A-Z\s]*$/i,
                              }"
                            >
                              <b-form-input
                                id="relationName"
                                v-model="relationName"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Relation Name"
                                v-b-tooltip.hover
                                title="Enter your name"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group label-for="relationCnic">
                            <label>
                              Relation CNIC
                              <span class="text-danger">*</span>
                            </label>
                            <validation-provider
                              #default="{ errors }"
                              name="Relation Cnic"
                              :rules="{
                                required: !callerSelf,
                                integer: true,
                                length: 13,
                              }"
                            >
                              <b-form-input
                                id="relationCnic"
                                v-model="relationCnic"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Relation Cnic"
                                :disabled="!callerSelf"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group label-for="relationContactNo">
                            <label> Relation Cell No </label>
                            <validation-provider
                              #default="{ errors }"
                              name="Cell No"
                              rules="integer|length:11"
                            >
                              <b-form-input
                                id="relationContactNo"
                                v-model="relationContactNo"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Relation Cell No"
                                :disabled="!callerSelf"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <!-- <b-col md="3" v-if="category">
                  <validation-provider #default="{ errors }" name="Subcategory" rules="required">
                    <b-form-group label-for="subCategory" :state="errors.length > 0 ? false : null">
                      <label>
                        Subcategory <span class="text-danger">*</span>
                      </label>
                      <b-row>
                        <b-col>
                          <v-select id="subCategory" inputId="id" label="name" v-model="subCategory"
                            :options="selectedSubcategories" placeholder="Subcategory" class="v-style-chooser"
                            :disabled="subcategoryDisabled" 
                            v-b-tooltip.hover title="Select subcategoty from dropdown menu "/>
                        </b-col>
                      </b-row>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col> -->
                <!-- <b-col md="3">
                  <label>Mark To:</label>
                  <v-select
                    id="selectedOrganization"
                    inputId="id"
                    label="name"
                    v-model="selectedOrganization"
                    :options="organizationsList"
                    placeholder="Select organization"
                    class="v-style-chooser"
                  />
                </b-col> -->
                <b-col md="3">
                  <b-form-group label-for="documents">
                    <validation-provider #default="{ errors }" name="Document">
                      <label>Document (PDF only)</label>
                      <b-form-file
                        ref="fileInput"
                        id="documents"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".pdf"
                        @change="upload"
                      />
                      <small class="text-danger" v-if="documentError === 1"
                        >Max {{ maxFilesCount }} files are allowed</small
                      >
                      <small class="text-danger" v-if="documentError === 2"
                        >Each file size should be less than
                        {{ maxFileSize }} MB</small
                      >
                      <small class="text-danger" v-if="documentError === 3"
                        >Only pdf is allowed</small
                      >
                      <div class="d-flex flex-wrap">
                        <b-badge
                          pill
                          variant="primary"
                          v-for="(file, index) in uploadedFiles"
                          :key="index"
                          class="my-1 mx-25"
                        >
                          {{ file.name }}
                          <feather-icon
                            size="24"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeFile(index)"
                          />
                        </b-badge>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
            <b-card no-body class="mb-0">
              <label>Complaint Detail</label>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label-for="comment">
                        <quill-editor
                          v-model="comment"
                          style="height: 200px"
                          :options="option"
                        />

                        <!-- <validation-provider #default="{ errors }" name="Comment">
                          <b-form-textarea id="comment" v-model="comment" :state="errors.length > 0 ? false : null"
                            placeholder="comment" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider> -->
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </template>
      </validation-observer>
      <!-- <validation-observer ref="complaintMarkingFormValidation">
        <template>
          <div>
            <b-card class="mb-0">
              <b-row>
                <b-col md="3" class="mt-1">
                  Mark To:
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <v-select id="selectedOrganization" inputId="id" label="name" v-model="selectedOrganization"
                    :options="organizationsList" placeholder="Select organization" class="v-style-chooser" />
                </b-col>
              </b-row>
              <b-form-group label-for="documents">
                <validation-provider #default="{ errors }" name="Document">
                  <label>Document (PDF only)</label>
                  <b-form-file ref="fileInput" id="documents" placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..." accept=".pdf" @change="upload" />
                  <small class="text-danger" v-if="documentError === 1">Max {{ maxFilesCount }} files are allowed</small>
                  <small class="text-danger" v-if="documentError === 2">Each file size should be less than {{ maxFileSize
                  }} MB</small>
                  <small class="text-danger" v-if="documentError === 3">Only pdf is allowed</small>
                  <div class="d-flex flex-wrap">
                    <b-badge pill variant="primary" v-for="(file, index) in uploadedFiles" :key="index"
                      class="my-1 mx-25">
                      {{ file.name }}
                      <feather-icon size="24" icon="XIcon" class="cursor-pointer" @click="removeFile(index)" />
                    </b-badge>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-card>
          </div>
        </template>
      </validation-observer> -->
    </b-form>
    <b-form-group class="mt-1 text-right">
      <!-- <b-button type="submit" variant="outline-success" pill class="mr-1" @click="saveDraft">
        Draft
      </b-button> -->
      <b-button
        type="button"
        variant="outline-secondary"
        pill
        class="mr-1"
        @click="reset"
      >
        Reset
      </b-button>
      <b-button
        type="submit"
        variant="primary"
        pill
        class="mr-1"
        @click="validationForm"
      >
        Submit
      </b-button>
    </b-form-group>
    <document-create-modal
      id="document-create-modal"
      :isComplaintDocument="isComplaintDocument"
      @modalClosed="onDocumentCreateModalClosed"
      :key="`create-document-${documentCreateModalCount}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import DocumentCreateModal from "@/components/document/DocumentCreateModal.vue";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VuePerfectScrollbar,
    draggable,
    VueSelectPaginated,
    DocumentCreateModal,
    quillEditor,
  },
  // props: {
  //   complaint: Object,
  //   allowEdit: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  mixins: [util],
  data() {
    return {
      draft: false,
      homeFormState: false,
      markingFormState: false,
      required,
      type: "",
      rollNo: "",
      name: "",
      dateOfBirth: "",
      cnic: "",
      gender: "",
      homeDistrict: "",
      telNo: "",
      cellNo: "",
      imageURL: "",
      cnicOrBform: null,
      uploadImage: null,

      // Complaint Fields
      comment: "",
      markingComment: "",
      requestSource: null,
      callerType: null,
      relationType: null,
      complainantType: null,
      complaintType: null,
      category: null,
      subCategory: null,
      requestSources: [],
      callerTypes: [],
      relationTypes: [],
      complainantTypes: [],
      complaintTypes: [],
      subCategories: [],
      categories: [],
      commentOptions: {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            ["bold", "italic", "underline", "strike"], // toggled buttons

            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
          ],
        },
      },
      option: {
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
            ["clean"], // remove formatting button
          ],
        },
      },
      subcategoryDisabled: false,
      allCategories: [],
      selectedSubcategories: [],

      callerSelf: false,

      // Complainant Fields
      email: "",
      armyNo: "",
      cnic: "",
      unit: "",
      firstName: "",
      lastName: "",
      contactNo: "",
      contactNo2: "",
      telephoneNo: "",
      rank: null,
      city: null,
      armyPrefix: null,
      maritalStatus: null,
      regiment: null,
      servingStatus: 2,
      armyPrefixes: [],
      maritalStatuses: [],
      regiments: [],
      servingStatuses: [],
      ranks: [],
      cities: [],
      isArmy: true,

      //Relation Fields
      relationName: "",
      relationCnic: "",
      relationContactNo: "",

      //FollowUp Fields
      followUpName: "",
      followUpContactNo: "",
      followUpTelephoneNo: "",

      //SearchField
      searchField: null,

      // Marking
      selectedOrganization: 33,
      organizationsList: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 100,
        wheelPropagation: false,
        useBothWheelAxes: false,
        suppressScrollX: true,
        FavoriteUserKey: 0,
        FavoriteUser: null,
      },
      markingOptions: {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            ["bold", "italic", "underline", "strike"], // toggled buttons

            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
          ],
        },
      },

      // Documents
      documents: [],
      markDocuments: [],
      documentsKey: 0,
      documentCreateModalCount: 0,
      isComplaintDocument: false,
      complainantsList: [],
      saveAsDraft: false,

      documentError: 0,
      maxFilesCount: 5,
      maxFileSize: 5,
      uploadedFiles: [],
      acceptedFileTypes: ".pdf",

      currentComplainantTypes: [],
    };
  },

  async mounted() {
    const res = await this.getOrganizationsUnpaginated();
    this.organizationsList = res.data;
    await this.getComplaintFormFieldsData();
    let complainantsUnpaginated = await this.getComplainantsUnpaginated();
    this.complainantsList = complainantsUnpaginated.data;
    for (let i = 0; i < this.complainantsList.length; i++) {
      this.complainantsList[i]["text"] =
        "Name: " +
        this.complainantsList[i]["first_name"] +
        " " +
        this.complainantsList[i]["last_name"] +
        " -- CNIC: " +
        this.complainantsList[i]["cnic"] +
        " -- Army No: " +
        this.complainantsList[i]["army_no"];
    }
    for (let i = 0; i < this.requestSources.length; i++) {
      if (this.requestSources[i].name.toLowerCase().includes("afp")) {
        this.requestSource = this.requestSources[i];
      }
    }
    for (let i = 0; i < this.complaintTypes.length; i++) {
      if (this.complaintTypes[i].name.toLowerCase().includes("army")) {
        this.complaintType = this.complaintTypes[i];
      }
    }
    for (let i = 0; i < this.callerTypes.length; i++) {
      if (this.callerTypes[i].name.toLowerCase().includes("self")) {
        this.callerType = this.callerTypes[i];
      }
    }
    // this.contactNo = this.getLoggedInUser.mobile
    // this.cnic = this.getLoggedInUser.cnic
  },
  methods: {
    ...mapActions({
      createComplaint: "appData/createComplaint",
      getComplaintFormFields: "appData/getComplaintFormFields",
      getOrganization: "appData/getOrganization",
      getOrganizations: "appData/getOrganizations",
      getUser: "appData/getUser",
      getDocuments: "appData/getDocuments",
      getUsersUnPaginated: "appData/getUsersUnPaginated",
      getComplainantsUnpaginated: "appData/getComplainantsUnpaginated",
      getOrganizationsUnpaginated: "appData/getOrganizationsUnpaginated",
    }),

    async upload(event) {
      this.documentError = 0;
      const files = event.target.files;
      if (this.uploadedFiles.length + files.length > this.maxFilesCount) {
        this.documentError = 1;
        return;
      }
      if (!files[0].name.includes(".pdf")) {
        this.documentError = 3;
        return;
      }

      const filesToAdd = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size > this.maxFileSize * 1000 * 1024) {
          this.documentError = 2;
          return;
        }

        filesToAdd.push(file);
      }

      this.uploadedFiles = [...this.uploadedFiles, ...filesToAdd];
    },
    removeFile(index) {
      this.documentError = 0;
      this.uploadedFiles.splice(index, 1);
    },

    async saveDraft() {
      this.saveAsDraft = true;
      await this.validationForm();
    },
    async validationForm() {
      let success = await this.$refs.complaintCreateFormValidation.validate();
      let cnicMatch = false;
      if (this.cnic === this.relationCnic) {
        cnicMatch = true;
        this.$refs.complaintCreateFormValidation.setErrors({
          cnic: ["CNIC cannot be the same as relation CNIC"],
        });
      }

      if (success && !cnicMatch) {
        this.homeFormState = false;
        // success =
        //   await this.$refs.complaintMarkingFormValidation.validate();
        // if (success) {
        //   this.markingFormState = false;
        //   await this.submit();
        // } else {
        //   this.markingFormState = true;
        // }
        this.markingFormState = false;
        await this.submit();
      } else {
        this.homeFormState = true;
      }
    },
    createDocument(isComplaintDocument) {
      this.isComplaintDocument = isComplaintDocument;
      this.documentCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("document-create-modal");
      });
    },
    async onDocumentCreateModalClosed(documents, isComplaintDocument) {
      if (isComplaintDocument) {
        this.documents = this.documents.concat(documents);
      } else {
        this.markDocuments = this.markDocuments.concat(documents);
      }
      this.documentsKey += 1;
    },
    async getComplaintFormFieldsData() {
      try {
        const res = await this.getComplaintFormFields();

        this.requestSources = res.data.request_sources;
        this.callerTypes = res.data.caller_types;
        this.relationTypes = res.data.relation_types;
        this.complainantTypes = res.data.complainant_types;
        this.complaintTypes = res.data.complaint_types;

        this.armyPrefixes = res.data.army_prefixes;
        this.maritalStatuses = res.data.marital_statuses;
        this.regiments = res.data.regiments;
        this.servingStatuses = res.data.serving_statuses;
        this.ranks = res.data.rank_types;
        this.cities = res.data.cities;

        this.categories = res.data.categories;
        this.subCategories = res.data.subcategories;
      } catch (error) {
        this.displayError(error);
      }
    },

    async submit() {
      if (this.documentError === 0) {
        try {
          if (!this.selectedOrganization) {
            this.displayError("Complaint has to be marked");
            return;
          }
          // const mark_documents_id = this.markDocuments.map((document) => {
          //   return document.id;
          // });

          let formData = new FormData();

          this.uploadedFiles.map((file, index) => {
            formData.append(`file${index}`, file);
          });

          let dataToInsert = {
            // history: [{
            //   documents_id: mark_documents_id,
            //   marked_from: this.getLoggedInUser.id,
            //   marked_to: this.selectedOrganization.id,
            // }],
            complainant: {
              complaint_type: this.complaintType.id,
              army_prefix: this.armyPrefix ? this.armyPrefix.id : null,
              army_no: this.armyNo,
              cnic: this.cnic,
              rank: this.rank ? this.rank.id : null,
              unit: this.unit,
              marital_status: this.maritalStatus.id,
              first_name: this.firstName,
              last_name: this.lastName,
              regiment: this.regiment ? this.regiment.id : null,
              serving_status: this.servingStatus,
              contact_no: this.contactNo,
              contact_no2: this.contactNo2,
              email: this.email,
              city: this.city.id,
              telephone_no: this.telephoneNo,
              created_by: this.getLoggedInUser.id,
              updated_by: this.getLoggedInUser.id,
            },
            // followup: {
            //   name: this.followUpName,
            //   contact_no: this.followUpContactNo,
            //   telephone_no: this.followUpTelephoneNo,
            //   created_by: this.getLoggedInUser.id,
            //   updated_by: this.getLoggedInUser.id,
            // },

            marked_from: this.getLoggedInUser.id,
            marked_to: this.selectedOrganization,

            request_source: this.requestSource.id,
            caller_type: this.callerType.id,
            complainant_type: null,
            relation_type: this.relationType ? this.relationType.id : null,
            comment: this.comment,
            category: null,
            draft: this.saveAsDraft,
            created_by: this.getLoggedInUser.id,
            updated_by: this.getLoggedInUser.id,
          };
          if (this.callerSelf == false) {
            dataToInsert["relation"] = {
              name: this.relationName,
              cnic: this.relationCnic,
              contact_no: this.relationContactNo,
              created_by: this.getLoggedInUser.id,
              updated_by: this.getLoggedInUser.id,
            };
          }
          formData.append("data", JSON.stringify(dataToInsert));
          const res = await this.createComplaint(formData);
          if (res.status === 201) {
            this.displaySuccess("Complaint created successfully");
            this.$nextTick(() => {});
            this.reset();
            this.$refs.complaintCreateFormValidation.reset();
            
          }
        } catch (error) {
          this.displayError(error);
          this.reset();
        }
      } else {
        this.show = false;
        this.$swal({
          title: `File size should be less than ${this.maxFileSize} MB or Total files should be less than ${this.maxFilesCount}`,
          icon: "error",
          timer: 3000,
        });
        this.documentError = 0;
        this.uploadedFiles = [];
        this.$refs.fileInput.reset();
        this.$refs.complaintCreateFormValidation.reset();
      }
      this.$emit("modalClosed");
    },

    reset() {
      this.draft = false;
      this.homeFormState = false;
      this.markingFormState = false;
      this.required = false;
      this.type = "";
      this.rollNo = "";
      this.name = "";
      this.dateOfBirth = "";
      this.cnic = "";
      this.gender = "";
      this.homeDistrict = "";
      this.telNo = "";
      this.cellNo = "";
      this.imageURL = "";
      this.cnicOrBform = null;
      this.uploadImage = null;

      // Complaint Fields
      this.comment = "";
      this.markingComment = "";
      this.requestSource = null;
      this.callerType = null;
      this.relationType = null;
      this.complainantType = null;
      this.complaintType = null;
      this.category = null;
      this.subCategory = null;

      this.subcategoryDisabled = false;
      this.selectedSubcategories = [];

      this.callerSelf = false;

      // Complainant Fields
      this.email = "";
      this.armyNo = "";
      this.cnic = "";
      this.unit = "";
      this.firstName = "";
      this.lastName = "";
      this.contactNo = "";
      this.contactNo2 = "";
      this.telephoneNo = "";
      this.rank = null;
      this.city = null;
      this.armyPrefix = null;
      this.maritalStatus = null;
      this.regiment = null;
      this.servingStatus = 2;
      this.isArmy = true;
      this.uploadedFiles =[];

      //Relation Fields
      this.relationName = "";
      this.relationCnic = "";
      this.relationContactNo = "";

      //FollowUp Fields
      this.followUpName = "";
      this.followUpContactNo = "";
      this.followUpTelephoneNo = "";

      //SearchField
      this.searchField = null;

      for (let i = 0; i < this.complainantsList.length; i++) {
        this.complainantsList[i]["text"] =
          "Name: " +
          this.complainantsList[i]["first_name"] +
          " " +
          this.complainantsList[i]["last_name"] +
          " -- CNIC: " +
          this.complainantsList[i]["cnic"] +
          " -- Army No: " +
          this.complainantsList[i]["army_no"];
      }
      for (let i = 0; i < this.requestSources.length; i++) {
        if (this.requestSources[i].name.toLowerCase().includes("afp")) {
          this.requestSource = this.requestSources[i];
        }
      }
      for (let i = 0; i < this.complaintTypes.length; i++) {
        if (this.complaintTypes[i].name.toLowerCase().includes("army")) {
          this.complaintType = this.complaintTypes[i];
        }
      }
      for (let i = 0; i < this.callerTypes.length; i++) {
        if (this.callerTypes[i].name.toLowerCase().includes("self")) {
          this.callerType = this.callerTypes[i];
        }
      }
      this.$refs.complaintCreateFormValidation.reset();

      this.$refs.fileInput.reset();
    },
  },
  computed: {
    ...mapGetters({
      getLoggedInUser: "appData/getUser",
      hasPermission: "appData/hasPermission",
    }),
    imgURL() {
      return this.imageURL;
    },
  },
  watch: {
    searchField(newValue, oldValue) {
      this.complaintType = null;
      this.armyPrefix = null;
      this.armyNo = "";
      this.cnic = "";
      this.rank = null;
      this.unit = "";
      this.maritalStatus = null;
      this.firstName = "";
      this.lastName = "";
      this.regiment = null;
      this.servingStatus = null;
      this.contactNo = "";
      this.contactNo2 = "";
      this.email = "";
      this.city = null;
      this.telephoneNo = "";

      if (newValue) {
        for (let i = 0; i < this.complaintTypes.length; i++) {
          if (this.complaintTypes[i].id == newValue.complaint_type_id) {
            this.complaintType = this.complaintTypes[i];
          }
        }
        for (let i = 0; i < this.armyPrefixes.length; i++) {
          if (this.armyPrefixes[i].id == newValue.army_prefix_id) {
            this.armyPrefix = this.armyPrefixes[i];
          }
        }
        for (let i = 0; i < this.ranks.length; i++) {
          if (this.ranks[i].id == newValue.rank_id) {
            this.rank = this.ranks[i];
          }
        }
        for (let i = 0; i < this.maritalStatuses.length; i++) {
          if (this.maritalStatuses[i].id == newValue.marital_status_id) {
            this.maritalStatus = this.maritalStatuses[i];
          }
        }
        for (let i = 0; i < this.regiments.length; i++) {
          if (this.regiments[i].id == newValue.regiment_id) {
            this.regiment = this.regiments[i];
          }
        }
        for (let i = 0; i < this.servingStatuses.length; i++) {
          if (this.servingStatuses[i].id == newValue.serving_status_id) {
            this.servingStatus = this.servingStatuses[i];
          }
        }
        for (let i = 0; i < this.cities.length; i++) {
          if (this.cities[i].id == newValue.city_id) {
            this.city = this.cities[i];
          }
        }

        this.armyNo = newValue.army_no;
        this.cnic = newValue.cnic;
        this.unit = newValue.unit;
        this.firstName = newValue.first_name;
        this.lastName = newValue.last_name;
        this.contactNo = newValue.contact_no;
        this.contactNo2 = newValue.contact_no2;
        this.email = newValue.email;
        this.telephoneNo = newValue.telephone_no;
      }
    },
    category(newValue, oldValue) {
      if (newValue) {
        this.subCategory = null;
        this.selectedSubcategories = [];
        this.subcategoryDisabled = false;
        for (let i = 0; i < this.subCategories.length; i++) {
          if (this.subCategories[i].parent == newValue.id) {
            this.selectedSubcategories.push(this.subCategories[i]);
          }
        }
        if (
          this.selectedSubcategories === undefined ||
          this.selectedSubcategories.length == 0
        ) {
          this.subcategoryDisabled = true;
        }
      }
    },
    complaintType(newValue, oldValue) {
      if (newValue) {
        if (newValue.name.toLowerCase().includes("army")) {
          this.isArmy = true;
        } else if (newValue.name.toLowerCase().includes("civilian")) {
          this.isArmy = false;
        }
      }
    },
    callerType(newValue, oldValue) {
      if (newValue) {
        if (newValue["name"].toLowerCase().includes("self")) {
          this.callerSelf = true;
          this.contactNo = this.getLoggedInUser.mobile;
          this.cnic = this.getLoggedInUser.cnic;
          this.relationContactNo = "";
          this.relationCnic = "";
          this.complainantType = null;
          this.currentComplainantTypes = [];
          for (let i = 0; i < this.complainantTypes.length; i++) {
            if (
              !this.complainantTypes[i].name
                .toLowerCase()
                .includes("shaheed") &&
              !this.complainantTypes[i].name.toLowerCase().includes("death") &&
              !this.complainantTypes[i].name.toLowerCase().includes("widow") &&
              !this.complainantTypes[i].name.toLowerCase().includes("orphan")
            ) {
              this.currentComplainantTypes.push(this.complainantTypes[i]);
            }
          }
        } else {
          this.callerSelf = false;
          this.relationContactNo = this.getLoggedInUser.mobile;
          this.relationCnic = this.getLoggedInUser.cnic;
          this.contactNo = "";
          this.cnic = "";
          // this.currentComplainantTypes = []
          this.complainantType = null;
          this.currentComplainantTypes = this.complainantTypes;
          // for (let i = 0; i < this.complainantTypes.length; i++) {
          //   if (!this.complainantTypes[i].name.toLowerCase().includes('shaheed') && !this.complainantTypes[i].name.toLowerCase().includes('death') && !this.complainantTypes[i].name.toLowerCase().includes('widow') && !this.complainantTypes[i].name.toLowerCase().includes('orphan')) {
          //     this.currentComplainantTypes.push(this.complainantTypes[i])
          //   }
          // }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/quill.scss";

// label {
//   font-size: 110%;
//   font-weight: 550 !important;
//   color: #000;
// }

.scroll-area-size {
  height: 30vh;
}

.scroll-area-size ul {
  height: 100%;
}

.quill-editor {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.quill-editor {
  overflow-y: hidden;
}

.vSelectStyle .vs__deselect {
  fill: #fff !important;
}
</style>
