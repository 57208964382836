var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"complaint-edit-modal","centered":"","hide-footer":"","size":"xl","no-close-on-esc":true,"no-close-on-backdrop":true,"dialog-class":"my-dialog-class"},on:{"hidden":_vm.reset}},[_c('h2',{staticClass:"mb-1"},[_vm._v("Complaint Details")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"complaintEditFormValidation"},[[_c('div',[_c('b-card',{staticClass:"mb-0 p-1",attrs:{"no-body":""}},[_c('div',{staticClass:"mb-0 p-1"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"cnic"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" CNIC "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"cnic","rules":"required|integer|length:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cnic","state":errors.length > 0 ? false : null,"placeholder":"Cnic","disabled":""},model:{value:(_vm.cnic),callback:function ($$v) {_vm.cnic=$$v},expression:"cnic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"cellNo"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Cell No ")]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Cell No","rules":"integer|length:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contactNo","state":errors.length > 0 ? false : null,"placeholder":"Cell No","disabled":""},model:{value:(_vm.contactNo),callback:function ($$v) {_vm.contactNo=$$v},expression:"contactNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"firstName"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" First Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"First Name","rules":{ required: _vm.required, regex: /^[A-Z\s]*$/i }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstName","state":errors.length > 0 ? false : null,"placeholder":"First Name","disabled":!_vm.allowEdit || !_vm.isDraft},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"lastName"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Last Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Last Name","rules":{ required: _vm.required, regex: /^[A-Z\s]*$/i }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastName","state":errors.length > 0 ? false : null,"placeholder":"Last Name","disabled":!_vm.allowEdit || !_vm.isDraft},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"mb-0 p-1"},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Marital Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"maritalStatus","state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Marital Status "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',[_c('v-select',{staticClass:"v-style-chooser",attrs:{"id":"maritalStatus","inputId":"id","label":"name","options":_vm.maritalStatuses,"placeholder":"Marital Status","disabled":""},model:{value:(_vm.maritalStatus),callback:function ($$v) {_vm.maritalStatus=$$v},expression:"maritalStatus"}})],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Serving Status","rules":{ required: _vm.isArmy ? _vm.isArmy : false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"servingStatus","state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Serving Status "),(_vm.isArmy)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',[_c('v-select',{staticClass:"v-style-chooser",attrs:{"id":"servingStatus","inputId":"id","label":"name","options":_vm.servingStatuses,"placeholder":"Serving Status","disabled":""},model:{value:(_vm.servingStatus),callback:function ($$v) {_vm.servingStatus=$$v},expression:"servingStatus"}})],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"contactNo2"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Cell No 2 ")]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Cell No","rules":"integer|length:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contactNo2","state":errors.length > 0 ? false : null,"placeholder":"Cell No 2","disabled":""},model:{value:(_vm.contactNo2),callback:function ($$v) {_vm.contactNo2=$$v},expression:"contactNo2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"email"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email ")]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Email","rules":{ email: _vm.email }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"placeholder":"Email","disabled":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"city","state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" City "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',[_c('v-select',{staticClass:"v-style-chooser",attrs:{"id":"city","inputId":"id","label":"name","options":_vm.cities,"placeholder":"City","disabled":""},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"telephoneNo"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Telephone No ")]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Tel No","rules":"integer|length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"telephoneNo","state":errors.length > 0 ? false : null,"placeholder":"Tel No","disabled":""},model:{value:(_vm.telephoneNo),callback:function ($$v) {_vm.telephoneNo=$$v},expression:"telephoneNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(!_vm.callerSelf)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"relationType","rules":{
                                required: _vm.callerSelf ? _vm.callerSelf : false,
                              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"relationType","state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Relation Type "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',[_c('v-select',{staticClass:"v-style-chooser",attrs:{"id":"relationType","inputId":"id","label":"name","options":_vm.relationTypes,"placeholder":"Relation Type","disabled":""},model:{value:(_vm.relationType),callback:function ($$v) {_vm.relationType=$$v},expression:"relationType"}})],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3292534533)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"relationName"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Relation Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,3126752354)},[_c('validation-provider',{attrs:{"name":"Relation Name","rules":{
                                  required: _vm.callerSelf ? _vm.callerSelf : false,
                                  regex: /^[A-Z\s]*$/i,
                                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"relationName","state":errors.length > 0 ? false : null,"placeholder":"Relation Name","disabled":""},model:{value:(_vm.relationName),callback:function ($$v) {_vm.relationName=$$v},expression:"relationName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1008137467)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"relationCnic"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Relation CNIC "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,2793503586)},[_c('validation-provider',{attrs:{"name":"Relation Cnic","rules":{
                                  required: _vm.callerSelf ? _vm.callerSelf : false,
                                  integer: true,
                                  length: 13,
                                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"relationCnic","state":errors.length > 0 ? false : null,"placeholder":"Relation Cnic","disabled":""},model:{value:(_vm.relationCnic),callback:function ($$v) {_vm.relationCnic=$$v},expression:"relationCnic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,541837147)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"relationContactNo"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Relation Cell No ")]},proxy:true}],null,false,2508555290)},[_c('validation-provider',{attrs:{"name":"Cell No","rules":"integer|length:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"relationContactNo","state":errors.length > 0 ? false : null,"placeholder":"Relation Cell No","disabled":""},model:{value:(_vm.relationContactNo),callback:function ($$v) {_vm.relationContactNo=$$v},expression:"relationContactNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,160516763)})],1)],1)],1)],1)],1):_vm._e()],1)],1),_c('b-row')],1),(_vm.markingAllowed)?_c('div',{staticClass:"mb-0 p-1"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"documents"}},[_c('validation-provider',{attrs:{"name":"Document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('label',[_vm._v("Document (PDF only)")]),_c('b-form-file',{ref:"fileInputEdit",attrs:{"id":"documentsEdit","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","accept":".pdf","disabled":""},on:{"change":_vm.upload},model:{value:(_vm.uploadedFiles),callback:function ($$v) {_vm.uploadedFiles=$$v},expression:"uploadedFiles"}}),(_vm.documentError === 1)?_c('small',{staticClass:"text-danger"},[_vm._v("Max "+_vm._s(_vm.maxFilesCount)+" files are allowed")]):_vm._e(),(_vm.documentError === 2)?_c('small',{staticClass:"text-danger"},[_vm._v("Each file size should be less than "+_vm._s(_vm.maxFileSize)+" MB")]):_vm._e(),(_vm.documentError === 3)?_c('small',{staticClass:"text-danger"},[_vm._v("Only pdf is allowed")]):_vm._e(),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.uploadedFiles),function(file,index){return _c('b-badge',{key:index,staticClass:"my-1 mx-25",attrs:{"pill":"","variant":"primary"}},[_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.viewDoc(_vm.complaint.documents[index])}}},[_vm._v(" "+_vm._s(file.name)+" ")])]),(_vm.allowEdit)?_c('b-col',{attrs:{"md":"3"}},[_c('feather-icon',{staticClass:"cursor-pointer mr-50",attrs:{"size":"24","icon":"XIcon"},on:{"click":function($event){return _vm.removeFile(index)}}})],1):_vm._e()],1)],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3063058752)})],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"mb-0 p-1"},[_c('h4',{staticClass:"font-weight-bolder"},[_vm._v("Complaint Detail")]),_c('b-row',[_c('b-col',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"comment"}},[_c('quill-editor',{staticStyle:{"height":"200px"},attrs:{"options":_vm.option,"disabled":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1)],1)],1)],1)],1)],1)])],1)]],2)],1),(
        _vm.complaint &&
        _vm.complaint.status !== _vm.complaintStatus.FINALIZED &&
        _vm.complaint.status !== _vm.complaintStatus.CLOSED
      )?_c('b-form-group',{staticClass:"mt-1 text-right"}):_vm._e()],1),_c('document-create-modal',{key:("create-document-" + _vm.documentCreateModalCount),attrs:{"id":"document-edit-modal","isComplaintDocument":_vm.isComplaintDocument},on:{"modalClosed":_vm.onDocumentCreateModalClosed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }